body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 0;
}

.container {
	max-width: 600px;
	margin: 0 auto;
	padding: 20px;
} 

form { 
	padding: 20px;
	border-radius: 8px;
}

h2 {
	text-align: center;
	margin-bottom: 20px;
}

label {
	display: block;
	font-weight: bold;
	margin-bottom: 8px;
}

input,
textarea {
	width: 100%;
	padding: 10px;
	margin-bottom: 20px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

/* button {
	color: white;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	width: 100%;
} */
/* 
button:hover {
	background-color: #45a049;
} */

/* @media (max-width: 480px) {
	form {
		max-width: 100%;
	}
}  */