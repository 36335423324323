.image-gallery-slide-wrapper {
	position: fixed;
	top: 9%;
	left: 0;
	right: 0;
	/* bottom: 0; */
}

/* Center the main image horizontally and vertically */
.image-gallery-slide {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 11px;
}

/* Make the main image take 90% of the screen width */
.image-gallery-slide img {
	max-width: 80%;
	max-height: 80vh;
	/* Limit the height to 90% of the viewport height */
	object-fit: contain;
	/* box-shadow: none; */
	/* Change to 'cover' if you prefer */
}

.image-gallery-slides {
	display:flex;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
	max-height: calc(100vh - 220px);
}

.image-gallery-content .image-gallery-slide.full-screen img {
	max-height: calc(100vh - 100px);
}

/* Position the thumbnails at the bottom of the screen */
.image-gallery-thumbnails-wrapper {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 16px;
	background-color: rgba(211, 211, 211);
}

/* Add some space between the thumbnails */
.image-gallery-thumbnails {
	margin-top: 8px;
	
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

/* Adjust the size of the thumbnails */
.image-gallery-thumbnail img {
	width: 60px;
	height: 45px;
	object-fit: cover;
	margin: 4px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
	width: 90%;
	line-height: 0;
	vertical-align: middle;
}

/* Increase the spacing between thumbnails */
.image-gallery-thumbnail:not(:last-child) {
	margin-right: 8px;
}

.image-gallery-fullscreen-button {
	cursor: pointer;
	position: absolute;
	/* top: 80%; */
	/* right: 12px; */
	z-index: 2;
	/* transform: translateY(-50%); */
}

.image-gallery-fullscreen-button:hover img {
	opacity: 0.7;
}

.image-gallery-play-button {
	cursor: pointer;
	position: absolute;
	/* top: 80%; */
	/* left: 12px; */
	z-index: 2;
	/* transform: translateY(-50%); */
}

.image-gallery-play-button:hover img {
	opacity: 0.7;
}

@media screen and (max-width: 768px) {
	.image-gallery-thumbnails-wrapper {
		padding: 8px;
	}

	.image-gallery-content
	.image-gallery-slide 
	.image-gallery-image 
	{
		display: flex;
		justify-content: center;
		align-items: center;
		/* margin-top: 11; */
		/* height: 50vh; */
		max-width: 80%;
		/* max-height: 210px; */
	}

	/* .image-gallery-slide img {
		max-width: 80%;
		max-height: 210px;
	} */
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
	.image-gallery-content .image-gallery-slide .image-gallery-image {
		max-height: calc(100vh - 250px);
	}
}

@media screen and (max-width: 768px) {
	.image-gallery-content .image-gallery-slide.full-screen img {
		max-height: 20vh;
	}
}

/* Style for iPad screens in full-screen mode (no size adjustments) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
	.image-gallery-content .image-gallery-slide.full-screen img {
		max-height: none;
		/* Remove any size adjustments for iPads */
	}
}